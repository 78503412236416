import * as React from "react";
import { Button, Divider, Header, Image, Container, Segment, Icon } from "semantic-ui-react";
import {withLayout} from "../components/Layout";
import car from "../images/car.jpg";

const AboutPage = () => {
  return (
    <Container>
      <Segment style={{ padding: "4em 0em" }} vertical>
        <Container text>
          <Header as="h3" style={{ fontSize: "2em" }}>
            About Hurst Weiss
          </Header>
          <Image floated="right" src={car}/>
          <p style={{ fontSize: "1.33em" }}>
            Hurst-Weiss Insurance has been serving the people of our community for over 80 years. We are proud of the fact that Mr. W. C. Kerr, who is seated in his first car (photo on right), a 1923 Ford Model T, has been a client since he first purchased that car. That is why we adopted Mr. Kerr, in his 1923 Ford Model T, as our company logo.
          </p>
          <p>
            Hurst-Weiss Insurance has over 80 years experience designing insurance programs for individuals, families and businesses. It is our job to take the overwhelming insurance options available and turn them into the security you seek. At Hurst-Weiss we will design a program that will change with you and your business. 
          </p>
          <p>
            Hurst-Weiss recognizes that in order to create the best insurance program, we must begin with top- of-the-line carriers. Our criteria include efficient claims processing, prompt professional attention and sound coverage - all at an affordable price. It is an elite few who we feel meet our high standards and yours.
          </p>
        </Container>
        <Divider
          as="h4"
          className="header"
          horizontal
          style={{ margin: "3em 0em", textTransform: "uppercase" }}
        >
          <a href="#">Ebook</a>
        </Divider>
        <Container text>
          <p>
          Understanding Commercial Insurance
          <br /><span style={{fontSize: "16px"}}>by Michael C. Konopelski</span>
          </p>
          <a href="/ebook">
            <Button size="huge">Read Online</Button>
          </a>
        </Container>
      </Segment>
    </Container>
  );
};

export default withLayout(AboutPage);
